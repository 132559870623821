import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Last Day of Test Week`}</em></p>
    <p>{`Front Squat 1-1-1-1 to a 1RM`}</p>
    <p>{`Thruster 1-1-1-1 to a 1RM`}</p>
    <p>{`then,`}</p>
    <p><strong parentName="p">{`“Omar”`}</strong></p>
    <p>{`10-Thrusters (95/65)`}</p>
    <p>{`15-Bar Facing Burpees`}</p>
    <p>{`20-Thrusters`}</p>
    <p>{`25-Bar Facing Burpees`}</p>
    <p>{`30-Thrusters`}</p>
    <p>{`35-Bar Facing Burpees`}</p>
    <p>{`For time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      